.rdrStaticRangeLabel, .rdrInputRanges {
    color: #000;
}

.react-calendar__navigation button {
    color: #000;
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    color: black;
}

.react-calendar button {
    color: #000;
}

.react-date-picker, .react-date-picker *, .react-date-picker *:before, .react-date-picker *:after {
    color: grey;
}

